<template>

<section id="cultura-form">
    <div class="row">
      <div class="col-12">
        <h3 class="section-head-title ps-0 my-3">
            <font-awesome-icon 
              icon="pen-to-square" 
              class="color-secondary"
            />
            Completa los siguientes campos para publicar la cultura
        </h3>
        <div class="card card-custom border-round-10 card-shadow border-0">
          <div class="card-body d-flex flex-column mh-700">
            <div 
              class="row flex-grow-1 mt-3">
              <div class="col-12 col-lg-3 d-flex flex-column mt-3 mt-lg-0">
                <h5 class="font-main text-secondary">
                  <font-awesome-icon 
                    icon="list" 
                    class="color-secondary"
                  />
                  Tipo de contenido
                </h5>
                <div class="card flex-grow-1">
                  <div class="card-body">
                    <h6 class="font-main-bold color-main text-center">Elementos</h6>
                    <div class="list-group">
                      <a 
                        v-for="elemento in elementos"
                        :key="elemento.id"
                        href="javascript:" 
                        @click="show_component = elemento.component"
                        class="list-group-item list-group-item-action border border-2 rounded-pill mb-2"
                        :class="elemento.component === show_component ? 'link-primary border-primary' : ' text-secondary'"
                      >
                        <i :class="elemento.icon"></i>
                        {{ elemento.label }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 d-flex flex-column mt-3 mt-lg-0">
                <h5 class="font-main text-secondary mt-3 mt-lg-0">
                  <font-awesome-icon 
                    icon="plus" 
                    class="color-secondary"
                  />
                  Agrega contenido
                </h5> 
                <div class="card flex-grow-1">
                  <div class="card-body d-flex flex-column flex-grow-1">
                    <h6 class="font-main-bold color-main text-center">Contenido interior cultura</h6>

                    <!--COMPONENT TITULO-->
                    <div 
                      v-if="show_component == 'Titulo'"
                      id="cultura-component-titulo">
                      <div class="row">
                        <div class="col-12">
                          <label class="form-label text-secondary">Ingresa un título</label>
                            <input 
                              type="text" 
                              class="form-control input-custom"
                              maxlength="150"
                              v-model="component.titulo"
                            >
                            <div class="form-text">
                              {{ component.titulo.length }}/150
                            </div>
                        </div>
                      </div>            
                    </div>

                    <!--COMPONENT TEXTO-->
                    <div 
                      v-if="show_component == 'Texto'"
                      id="noticias-component-texto" 
                      class="flex-grow-1 mb-3"
                    >
                      <div class="row flex-grow-1">
                        <div class="col-12">
                          <editor-content 
                            class="tiptap" 
                            :key="editor_key" 
                            :editor="editor" 
                            v-model="news_content"
                            ref="newsContent" 
                          />

                        </div>
                      </div>
                      <div class="d-flex align-items-center justify-content-between mt-2">
                        <div class="btn-group btn-wysiwyg">
                          <button 
                            class="btn btn-outline-secondary"
                            @click="editor.chain().focus().toggleBold().run()"
                            :class="{ 'active': editor.isActive('bold') }"
                          >
                            <font-awesome-icon icon="bold"/>
                          </button>
                          <button 
                            class="btn btn-outline-secondary"
                            @click="editor.chain().focus().toggleItalic().run()"
                            :class="{ 'active': editor.isActive('italic') }"
                          >
                            <font-awesome-icon icon="italic"/>
                          </button>
                          <button 
                            class="btn btn-outline-secondary"
                            @click="editor.chain().focus().toggleUnderline().run()"
                            :class="{ 'active': editor.isActive('underline') }"
                          >
                            <font-awesome-icon icon="underline"/>
                          </button>
                          <button 
                            class="btn btn-outline-secondary"
                            @click="editor.chain().focus().toggleBulletList().run()"
                            :class="{ 'active': editor.isActive('bulletList') }"
                          >
                            <font-awesome-icon class="icono" icon="list"/>
                          </button>
                          <button 
                            class="btn btn-outline-secondary"
                            @click="editor.chain().focus().toggleOrderedList().run()"
                            :class="{ 'active': editor.isActive('orderedList') }"
                          >
                            <font-awesome-icon class="icono" icon="list-ol"/>
                          </button>
                          <button 
                            class="btn btn-outline-secondary"
                            v-if="!editor.isActive('link')"
                            @click="setLink" 
                            content="Esta opción te permitirá insertar un enlace a una página web"
                            v-tippy="{ arrow: true }" 
                            :class="{ 'active': editor.isActive('link') }"
                          >
                            <font-awesome-icon class="icono" icon="link"/>
                          </button>
                          <button 
                            class="btn btn-outline-secondary"
                            v-if="editor.isActive('link')"
                            @click="editor.chain().focus().unsetLink().run()"
                            :class="{ 'active': editor.isActive('link') }" 
                          >
                            <font-awesome-icon class="icono" icon="link"/>
                          </button>
                        </div>                    
                        <div class="form-text">
                          {{ editor.storage.characterCount.characters() }}/{{ limit }}
                        </div>
                      </div>
                    </div>

                    <!--COMPONENT IMAGEN-->
                    <div 
                      v-if="show_component == 'Imagen'"
                      id="noticias-component-imagen" 
                      class="flex-grow-1 mt-3"
                    >
                      <div class="row mb-3">
                        <div class="col-12">
                          <div class="upload-image">
                            <label
                              v-if="component.img == '' "
                              class="upload-image-label" 
                              for="input-image">
                              <span class="upload-image-label-icon">
                                <i class="fa-solid fa-arrow-up-from-bracket color-secondary"></i>
                              </span>
                              <input 
                                type="file" 
                                id="input-image" 
                                @change="loadFiles($event)" 
                                accept=".png , .jpg, .jpeg"
                                style="display: none"
                              />
                              <span class="upload-image-label-text">
                                Haz clic aquí<br> 
                                <small>para seleccionar una imagen</small>
                              </span>
                            </label>
                            <div 
                              class="upload-image-preview" 
                              v-if="component.img !== '' "
                            >
                              <button 
                                @click="(component.foto = ''), (component.img = ''), (component.url = '')"
                                class="upload-image-preview-remove"   
                              >
                                <font-awesome-icon icon="times"/>
                              </button>
                              <img 
                                class="upload-image-preview-img"
                                :src="component.img"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--COMPONENT DOCUMENTO-->
                    <div                  
                      v-if="show_component == 'Documento'"
                      id="noticias-component-documento"
                      class="flex-grow-1 mt-3"
                    >
                      <div class="upload-image">
                        <label
                          v-if="component.pdf_prev == ''"
                          class="upload-image-label upload-image-label-document"
                          for="input-file">
                          <span class="upload-image-label-icon">
                          </span>
                          <input 
                            type="file" 
                            id="input-file" 
                            @change="loadFiles($event)" 
                            accept=".pdf"
                            style="display: none" 
                          />
                          <span class="upload-image-label-text">
                            <i class="fa-solid fa-arrow-up-from-bracket color-secondary"></i>
                            Haz clic aquí para seleccionar un documento
                          </span>
                        </label>
                        <div 
                          class="upload-image-preview upload-image-preview-document" 
                          v-if="component.pdf_prev !== ''"
                        >
                          <button 
                            @click="(component.pdf = ''), (component.pdf_prev = '')"
                            class="upload-image-preview-remove"   
                          >
                            <font-awesome-icon icon="times"/>
                          </button>
                          {{
                            component.docname.length == 0
                            ? `Documento sin nombre`
                            : `${component.docname.substring(0, 30)}${
                            30 > component.docname.length ? "" : "..."
                            }`
                            }}
                            {{
                            component.size == 0
                            ? ``
                            : `${((component.size * 10) / 10).toFixed(2)} MB`
                          }}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <div class="d-flex align-items-center justify-content-end">
                          <button 
                            v-if="edit_texto && step == 'content'" 
                            class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                            @click="cancelEdit()"
                          >
                            Cancelar
                          </button>
                          <button 
                            v-if="!edit_texto && step == 'content'" 
                            class="btn btn-custom-color-blue border-round-50 mw-100" 
                            :disabled="!handleStepsClassAgnadir()"
                            @click="push()"
                          >
                            Añadir
                          </button>
                          <button 
                            v-if="edit_texto && step == 'content'" 
                            class="btn btn-custom-color-blue border-round-50 mw-100" 
                            @click="pushEdited()"
                          >
                            Aceptar
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-3 d-flex flex-column mt-3 mt-lg-0">
                <h5 class="font-main text-secondary mt-3 mt-lg-0">
                  <font-awesome-icon 
                    icon="square-pen" 
                    class="color-secondary"
                  />
                  Edita o reorganiza
                </h5>
                <div class="card flex-grow-1 scroll-custom-550">
                  <div class="card-body">         
                    <h6 class="font-main-bold color-main text-center">Elementos Añadidos</h6>

                    <Draggable 
                      :animation="200" 
                      :list="comtent_objets" 
                      class="list-group" 
                      ghost-class="ghost" 
                      @end="onDrop">
                      <div 
                        v-for="(co, i) in comtent_objets" 
                        :key="i"
                      >
                        <div
                          v-if="co.contenido !== null"
                          class="list-group-item d-flex justify-content-between align-items-center border text-secondary rounded-pill mb-2 link-custom link-cursor"
                          :class="edit_selected_texto.title == co.title ? 'border-primary bg-info bg-opacity-10' : '' "
                          >
                          <span class="fs-5">
                            <i class="fa-solid fa-grip-vertical color-light"></i>
                            <span class="color-main ms-1">{{ co.title.split(";")[1] }}</span>
                            <span v-if="co.title.split(';')[0] === 'Reportero'"> Autor</span>
                            <span v-else> {{ co.title.split(";")[0] }}</span>
                          </span>
                          <span class="link-custom-hover">
                            <a 
                              v-if="co.contenido != null && !edit_texto" 
                              href="javascript:"
                              class="me-2"
                            >
                              <font-awesome-icon 
                                content="Edita este elemento" 
                                v-tippy="{ arrow: true }" 
                                icon="pen"
                                class="color-brown"
                                @click="editText(co, i)"
                              />
                            </a>
                            <a
                              v-if="co.contenido != null && !edit_texto" 
                              href="javascript:"
                              class="me-2"
                            >
                              <font-awesome-icon 
                                content="Elimina este elemento" 
                                v-tippy="{ arrow: true }" 
                                icon="trash-alt"
                                class="color-red"
                                @click="deleteComponent(co, i)"
                              />
                            </a>
                          </span>
                        </div>
                      </div>
                    </Draggable>

                  </div>
                </div>
              </div>   
            </div>

            <div class="row">
              <div class="col-12">
                <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
                  <button 
                    v-if="step == 'title'" 
                    class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                    @click="handleCleanStep()"
                    >
                    Cancelar
                  </button>
                  <button 
                    class="btn btn-custom-color-blue border-round-50 mw-100" 
                    @click="publish()"
                    :disabled="comtent_objets.length === 0"
                    v-if="!edit_cultura"
                  >
                  Publicar
                  </button>
                  <button 
                    class="btn btn-custom-color-blue border-round-50 mw-100" 
                    @click="publishEdit()"
                    :disabled="comtent_objets.length === 0"
                    v-if="edit_cultura"
                  >
                    Guardar
                  </button>
                </div> 
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    
    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="acceptStatus"
    />
    <Spinner v-if="show_spinner" />
</section>

</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import Link from "@tiptap/extension-link";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import Italic from "@tiptap/extension-italic";
import Underline from "@tiptap/extension-underline";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import CharacterCount from "@tiptap/extension-character-count";
import Status from "../Modales/Status.vue";
import { GET_CULTURAS } from "@/apollo/queries";
import { ADD_CULTURA, EDIT_CULTURA } from "@/apollo/mutations";
import Spinner from "../Spinner.vue";
import { mapActions, mapState } from "vuex";
import Draggable from "vuedraggable";
import { apolloClient } from "@/vue-apollo";

export default {
  components: {
    EditorContent,
    Status,
    Spinner,
    Draggable,
  },
  data() {
    return {
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      news_title: "",
      news_main_img: {
        width: "",
        height: "",
        base64: "",
        src: "",
        format: "",
      },
      comtent_objets_pre_edit: [],
      comtent_objets: [],
      component: {
        img: "",
        foto: "",
        url: "",
        video: "",
        video_prev: "",
        pdf: "",
        pdf_prev: "",
        videourl: "",
        pdf_name: "",
        user: "",
        titulo: "",
        texto: "",
      },
      disabled_component: false,
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      bad_document: 0,
      show_component: "Titulo",
      news_content: "",
      news_date: new Date(),
      news_format: null,
      step: "content",
      min_date: new Date(),
      display_button: "next",
      editor_key: 1,
      limit: 800,
      editor: new Editor({
        extensions: [
          Bold,
          Link,
          Document,
          Paragraph,
          Text,
          Italic,
          Underline,
          OrderedList,
          BulletList,
          ListItem,
          CharacterCount.configure({
            limit: 800,
          }),
        ],
        content: this.content,
        autofocus: false,
        onUpdate: ({ editor }) => {
          this.news_content = editor.getHTML();
        },
      }),

      content: "",
      user_switch: "",
      user_name: "",
      user_list: [],
      user: {
        id_usuario: "",
        primer_nombre: "",
        apellido_paterno: "",
        link: "",
        medio: "",
      },
      name_comp: "",
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      calendar: new Date(),
      open_calendar: false,
      key_calendar: 0,
      url_ex_app: "",
      url_ex_intranet: "",
      show_spinner: false,
      open_select: false,
      edit_cultura: false,
      edit_texto: false,
      edit_selected_texto: {},
      cultura_to_edit: {},
      tag_switch: "",
      video_switch: "",
      tags: [
        "Femenino",
        "Masculino",
        "Internos",
        "Externos",
        "Planta",
        "Teletrabajo",
        "Transporte ",
      ],
      fecha_modificable: "",
      hipervinculo_switch: false,
      addTags: [],
      dateTime: "00000",
      maskDateTime: "##:##",

      calendar_msg: "",
      calendar_mod: true,

      //FF
      elementos: [
        {
        id: 1,
        label: "Título",
        component: "Titulo",
        icon: "fa-solid fa-circle-h"
        },        {
        id: 2,
        label: "Texto",
        component: "Texto",
        icon: "fa-solid fa-align-left"
        },
        {
        id: 3,
        label: "Imagen",
        component: "Imagen",
        icon: "fa-regular fa-image"
        },
        {
        id: 4,
        label: "Documento",
        component: "Documento",
        icon: "fa-solid fa-file-lines"
        }
      ]
    };
  },
  computed: {
    ...mapState("cultureModule", ["culture_action"]),
    // Funcion del plugin del calendario para destacar el dia seleccionado

    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost",
      };
    },
    getMonth() {
      const date = new Date(this.news_date);
      let month = this.months[date.getMonth()];
      return month.slice(0, 3);
    },
    getDay() {
      const date = new Date(this.news_date);
      let day = "0" + date.getDate();
      return day.substr(-2);
    },
  },

  mounted() {
    setTimeout(() => {
      this.editor = new Editor({
        extensions: [
          Bold,
          Link,
          Document,
          Paragraph,
          Text,
          Italic,
          Underline,
          OrderedList,
          BulletList,
          ListItem,
          CharacterCount.configure({ limit: this.limit }),
        ],
        content: this.content,
        autofocus: false,
        onUpdate: ({ editor }) => {
          this.news_content = editor.getHTML();
        },
      });
    }, 500);
    this.getNoticia();
  },
  watch: {
    user_name: function () {
      if (this.user_name <= 1) {
        this.user_list = [];
      }
    },
    comtent_objets: function () {
      // for (let i = 0; i < this.comtent_objets.length; i++) {
      //   console.log(this.comtent_objets[i].tipo);
      // }
    },
    news_content(a, b) {
      if (a.length > 800) {
        this.news_content = b;
      }
    },
  },
  methods: {
    ...mapActions("cultureModule", ["addCultureAllAction"]),
    // Funcion para obtener noticia cuando se tiene que editar
    cancelEdit() {
      this.edit_texto = false;
      this.edit_selected_texto = {};
      this.component.foto = ""
      this.component.img= ""
      this.component.pdf= ""
      this.component.pdf_name= ""
      this.component.pdf_prev= ""
      this.component.titulo= ""
      this.component.texto= ""
      this.component.url= ""
      this.component.user= ""
      this.component.video= ""
      this.component.video_prev= ""
      this.component.videourl= ""
      console.log('component', this.component);
      this.cleanComponent();
    },
    selectCategorias(cats) {
      //console.log(cats);
      var filterCats = cats.filter(function (element) {
        return element.especial == "0";
      });
      return filterCats;
    },
    async getNoticia() {
      var cultura = {};
      if (this.culture_action.id_cultura == undefined) {
        const { data } = await apolloClient.query({
          query: GET_CULTURAS,
          variables: {
            id_empresa: this.id_empresa, //id segun la empresa, cuando este ok el login
          },
        });
        cultura = data.culturas;
      } else {
        //console.log('1',this.culture_action)
        cultura = this.culture_action;
      }
      if (cultura.id_cultura != null) {
        this.edit_cultura = true;
        /* this.min_date = ""; */
        Date.prototype.addHours = function (h) {
          this.setTime(this.getTime() + h * 60 * 60 * 1000);
          return this;
        };
        this.cultura_to_edit = cultura;
        for (let i = 0; i < cultura.descripcion.length; i++) {
          //console.log("cultura", cultura.descripcion[i]);
          var desc = cultura.descripcion[i];
          this.show_component = desc.tipo_descripcion.id_tipo;
          switch (this.show_component) {
            case "1":
              this.comtent_objets.push({
                title: `Titulo;(${this.duplicate("Titulo") + 1})`,
                tipo: "Titulo",
                id: desc.id_descripcion,
                contenido: desc.titulo,
              });
              break;
            case "2":
              this.comtent_objets.push({
                title: `Texto;(${this.duplicate("Texto") + 1})`,
                tipo: "Texto",
                id: desc.id_descripcion,
                contenido: desc.texto,
              });
              setTimeout(() => {
                this.editor = new Editor({
                  extensions: [
                    Bold,
                    Link,
                    Document,
                    Paragraph,
                    Text,
                    Italic,
                    Underline,
                    OrderedList,
                    BulletList,
                    ListItem,
                    CharacterCount.configure({ limit: this.limit }),
                  ],
                  content: this.content,
                  autofocus: false,
                  onUpdate: ({ editor }) => {
                    this.news_content = editor.getHTML();
                  },
                });

                this.editor_key = 1 + this.editor_key;
              }, 200);

              break;
            case "3":
              this.comtent_objets.push({
                title: `Imagen;(${this.duplicate("Imagen") + 1})`,
                tipo: "Imagen",
                id: desc.id_descripcion,
                contenido: {
                  foto: desc.url_documento,
                  img: desc.url_documento,
                  url: desc.url_documento,
                },
              });
              break;
            case "5":
              this.comtent_objets.push({
                title: `Documento;(${this.duplicate("Documento") + 1})`,
                tipo: "Documento",
                id: desc.id_descripcion,
                contenido: {
                  pdf: desc.url_documento,
                  url: desc.url_documento,
                  name: desc.url_documento,
                },
              });
              break;
            default:
              //console.log("no existe el tipo de componente");
              break;
          }
        }
        this.show_component = "Titulo";
        this.comtent_objets_pre_edit = this.comtent_objets;
      }
    },
    // publicar noticia
    publish() {
      this.show_spinner = true;
      const contenido = this.varPublish();
      this.$apollo
        .mutate({
          mutation: ADD_CULTURA,
          variables: contenido,
        })
        .then((res) => {
          var cultura = res.data.agregarNoticia;
          if (cultura) {
            this.show_spinner = false;
            this.$toast.open({
                message:'Cultura publicada correctamente.',
                type:'success',
                duration:6000,
                position:'top-right'
              });
            this.$Progress.finish();
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.$Progress.fail();
        });
    },
    //variables de contenido para publicar
    varPublish() {
      var Descrip = [];
      const e = this.comtent_objets;
      
      for (var i = 0; i < e.length; i++) {
        switch (e[i].tipo) {
          case "Titulo":
            Descrip.push({
              id_descripcion: `0`,
              id_tipo: "1",
              texto: "",
              titulo: e[i].contenido,
              documento: "",
              formato_documento: "",
            });
            break;
          case "Texto":
            Descrip.push({
              id_descripcion: `0`,
              id_tipo: "2",
              texto: e[i].contenido,
              titulo: "",
              documento: "",
              formato_documento: "",
            });
            break;
          case "Imagen":
            Descrip.push({
              id_descripcion: `0`,
              id_tipo: "3",
              texto: "",
              titulo: "",
              documento: e[i].contenido.foto,
              formato_documento: `${
                e[i].contenido.img.split(":")[1].split(";")[0]
              }`,
            });
            break;
          case "Video":
            Descrip.push({
              id_descripcion: `0`,
              id_tipo: "4",
              texto: "",
              titulo: "",
              documento: e[i].contenido.video,
              formato_documento: ['video/mp4'],
            });
            break;
          case "Documento":
            Descrip.push({
              id_descripcion: `0`,
              id_tipo: "5",
              texto: "",
              titulo: e[i].contenido.name,
              documento: e[i].contenido.pdf,
              formato_documento: `${
                e[i].contenido.url.split(":")[1].split(";")[0]
              }`,
            });
            break;
        }
      }
      const content_request = {
        contenido: {
          id_empresa: this.id_empresa,
          id_usuario: this.$ls.get("user").id_usuario,
          descripciones: Descrip,
        },
      };
      return content_request;
    },
    // publicar la edicion de la noticia
   async publishEdit() {
      const contenidos = this.varPublishEdit();
      if (contenidos != null) {
        this.show_spinner = true;
        //this.$Progress.start();
        await this.$apollo
          .mutate({
            mutation: EDIT_CULTURA,
            variables: contenidos,
          })
          .then((res) => {
            var cultura = res.data.editarCultura;
            this.show_spinner = false;
            this.addCultureAllAction(cultura);
            this.goBack();
            this.$toast.open({
              message:'Cultura editada correctamente.',
              type:'success',
              duration:6000,
              position:'top-right'
            });
          })
          .catch((err) => {
            console.log("err editar cultura:", err);
            this.$toast.open({
              message:'Ocurrió un problema al editar la Cultura, intentalo nuevamente.',
              type:'success',
              duration:6000,
              position:'top-right'
            });
          });
      } else {
        console.log("noEdit");
      }
    },
    goBack(){
      this.$router.push({
       name:'culture-list' 
      })
    },
    //variables de contenido para publicar la edicion
    varPublishEdit() {
      const e = this.comtent_objets;
      if (this.compareArray(e, this.comtent_objets_pre_edit)) {
        var Descrip = [];
        for (var i = 0; i < e.length; i++) {
          //console.log(e[i].id == undefined);
          switch (e[i].tipo) {
            case "Titulo":
              Descrip.push({
                id_descripcion: e[i].id == undefined ? "" : e[i].id,
                id_tipo: "1",
                texto: "",
                titulo: e[i].contenido == null ? "delete" : `${e[i].contenido}`,
                documento: "",
                formato_documento: "",
              });
              break;
            case "Texto":
              Descrip.push({
                id_descripcion: e[i].id == undefined ? "" : e[i].id,
                id_tipo: "2",
                texto: e[i].contenido == null ? "delete" : `${e[i].contenido}`,
                titulo: "",
                documento: "",
                formato_documento: "",
              });
              break;
            case "Imagen":
              Descrip.push({
                id_descripcion: e[i].id == undefined ? "" : e[i].id,
                id_tipo: "3",
                texto: "",
                titulo: "",
                documento:
                  e[i].contenido == null
                    ? "delete"
                    : e[i].id == undefined
                    ? e[i].contenido.foto
                    : e[i].contenido.foto.includes("https://")
                    ? ""
                    : e[i].contenido.foto,
                formato_documento:
                  e[i].contenido == null
                    ? ""
                    : e[i].id == undefined
                    ? `${e[i].contenido.img.split(":")[1].split(";")[0]}`
                    : e[i].contenido.img.includes("https://")
                    ? e[i].contenido.img.split(".")[
                        e[i].contenido.img.split(".").length - 1
                      ]
                    : `${e[i].contenido.img.split(":")[1].split(";")[0]}`,
              });
              break;
            case "Documento":
              Descrip.push({
                id_descripcion: e[i].id == undefined ? "" : e[i].id,
                id_tipo: "5",
                texto: "",
                titulo: "",
                documento:
                  e[i].contenido == null
                    ? "delete"
                    : e[i].id == undefined
                    ? e[i].contenido.pdf
                    : "",
                formato_documento:
                  e[i].contenido == null
                    ? ""
                    : e[i].id == undefined
                    ? `${e[i].contenido.url.split(":")[1].split(";")[0]}`
                    : e[i].contenido.url.split(".")[
                        e[i].contenido.url.split(".").length - 1
                      ],
              });
              break;

             case "Video":
              Descrip.push({
                id_descripcion: e[i].id == undefined ? "" : e[i].id,
                id_tipo: "4",
                texto: "",
                titulo: "",
                documento:
                  e[i].contenido == null
                    ? ""
                    : e[i].id == undefined
                    ? e[i].contenido.video
                    : "",
                formato_documento:
                  e[i].contenido == null
                  ? ""
                  :  ['mp4']
              });
              break; 
            
          }
        }
        //console.log("des", Descrip);
        const content_request = {
          id_cultura: this.cultura_to_edit.id_cultura,
          contenido: {
            id_empresa: this.id_empresa,
            id_usuario: this.$ls.get("user").id_usuario,
            descripciones: Descrip,
          },
        };
        return content_request;
      } else {
        return null;
      }
    },
    //compara dos array
    compareArray(a, b) {
      //console.log("a:", a);
      //console.log("b", b);
      var iguales = 0;
      const arr_one = a.length;
      if (arr_one == b.length) {
        for (let i = 0; i < arr_one; i++) {
          if (JSON.stringify(a[i]) === JSON.stringify(b[i])) {
            iguales = iguales + 1;
          }
        }
      }
      if (arr_one == iguales) {
        return true;
      } else {
        return false;
      }
    },
    // Funcion para mostrar el numero de cada paso
    showSection(section) {
      switch (section) {
        case "main-img":
          if (this.step == "main-img") {
            return true;
          }
          break;
        case "title":
          if (this.step == "title") {
            return true;
          }
          break;
        case "content":
          if (this.step == "content") {
            return true;
          }
          break;
        case "preview":
          if (this.step == "preview") {
            return true;
          }
          break;
        case "date":
          if (this.step == "date") {
            return true;
          }
          break;
        default:
          break;
      }
    },

    handleStepsClassAgnadir() {
      switch (this.show_component) {
        case "Titulo":
          if (this.component.titulo != "") {
            return "active";
          }
          break;
        case "Texto":
          if (this.news_content != "" && this.news_content != "<p></p>") {
            return "active";
          }
          break;
        case "Imagen":
          if (this.component.img) {
            return "active";
          }
          break;
/*         case "Video":
          if (this.component.video.length > 0) {
            return "active";
          }
          if (this.component.videourl.length > 0) {
            return "active";
          }
          break; */
        case "Documento":
          if (this.component.pdf) {
            return "active";
          }
          break;
        default:
          return "";
      }
    },
    handlePreStep() {},
    handleCleanStep() {
      this.$router.push({
        name: "culture-add",
      });
      setTimeout(() => {
        this.edit_cultura = false;

        this.news_title = "";
        this.news_main_img = {
          width: "",
          height: "",
          base64: "",
          src: "",
          format: "",
        };
        this.comtent_objets_pre_edit = [];
        this.comtent_objets = [];
        this.component = {
          img: "",
          foto: "",
          url: "",
          video: "",
          video_prev: "",
          pdf: "",
          pdf_prev: "",
          videourl: "",
          pdf_name: "",
        };
        this.user_switch = "";
        this.user_name = "";
        this.user_list = [];
        this.user = {
          id_usuario: "",
          primer_nombre: "",
          apellido_paterno: "",
          link: "",
          medio: "",
        };
        this.dateTime = "00000";
        this.calendar = new Date();
        this.open_calendar = false;
        this.min_date = new Date();
        this.calendar_msg = "";
        this.calendar_mod = true;
      }, 300);
    },
    // Funcion para seleccionar fecha
    onDayClick(day) {
      //console.log(day);
      if (day.isDisabled == false) {
        this.news_date = day.id;
      }
    },
    // Funcion para seleccionar formato de noticia
    selectFormat(format) {
      this.news_format = format;
    },
    // Funcion para subir imagen secundaria
    handleSecImg(img) {
      this.news_sec_img = {
        width: img.width,
        height: img.height,
        base64: img.base64,
        src: img.src,
        format: img.format,
      };
      // console.log(this.post_img);
    },
    // Funcion para agregar enlaces en el texto de contenido
    setLink() {
      const url = window.prompt("URL");
      this.editor.chain().focus().toggleLink({ href: url }).run();
    },
    // Funcion para eliminar vista previa de imagenes subidas, img_type = puede ser img principal o secundaria
    deletePreview(img_type) {
      if (img_type == "main") {
        this.news_main_img = {
          width: "",
          height: "",
          base64: "",
          src: "",
          format: "",
        };
      }
      if (img_type == "sec") {
        this.news_sec_img = {
          width: "",
          height: "",
          base64: "",
          src: "",
          format: "",
        };
      }
    },
    upList(i) {
      //console.log(this.comtent_objets);
      var element = this.comtent_objets[i];
      //console.log(element);
      this.comtent_objets.splice(i, 1);
      this.comtent_objets.splice(i - 1, 0, element);
    },
    push() {
      //console.log(this.user);
      switch (this.show_component) {
        case "Titulo":
          if (this.component.titulo.length > 0) {
            this.comtent_objets.push({
              title: `Titulo;(${this.duplicate("Titulo") + 1})`,
              tipo: "Titulo",
              contenido: this.component.titulo,
            });
            this.component.titulo = "";
            setTimeout(() => {
              this.editor = new Editor({
                extensions: [
                  Bold,
                  Link,
                  Document,
                  Paragraph,
                  Text,
                  Italic,
                  Underline,
                  BulletList,
                  OrderedList,
                  ListItem,
                  CharacterCount.configure({ limit: this.limit }),
                ],
                content: this.content,
                autofocus: false,
                onUpdate: ({ editor }) => {
                  this.news_content = editor.getHTML();
                },
              });
              this.editor_key = 1 + this.editor_key;
            }, 200);
          }
          this.name_comp = "";
          break;
        case "Texto":
          if (this.news_content !== "" && this.news_content != "<p></p>") {
            this.comtent_objets.push({
              title: `Texto;(${this.duplicate("Texto") + 1})`,
              tipo: "Texto",
              contenido: this.news_content,
            });
            this.news_content = "";
            setTimeout(() => {
              this.editor = new Editor({
                extensions: [
                  Bold,
                  Link,
                  Document,
                  Paragraph,
                  Text,
                  Italic,
                  Underline,
                  BulletList,
                  OrderedList,
                  ListItem,
                  CharacterCount.configure({ limit: this.limit }),
                ],
                content: this.content,
                autofocus: false,
                onUpdate: ({ editor }) => {
                  this.news_content = editor.getHTML();
                },
              });
              this.editor_key = 1 + this.editor_key;
            }, 200);
          }
          this.name_comp = "";
          break;
        case "Imagen":
          if (this.component.img) {
            this.comtent_objets.push({
              title: `Imagen;(${this.duplicate("Imagen") + 1})`,
              tipo: "Imagen",
              contenido: {
                foto: this.component.foto,
                img: this.component.img,
                url: this.component.url,
              },
            });
            this.component.foto = "";
            this.component.img = "";
            this.component.url = "";
            this.hipervinculo_switch = false;
          }
          this.name_comp = "";
          break;
        case "Video":
          if (this.component.video.length > 0) {
            this.comtent_objets.push({
              title: `Video;(${this.duplicate("Video") + 1})`,
              tipo: "Video",
              contenido: {
                video: this.component.video,
                url: this.component.video_prev,
              },
            });
            this.component.video = "";
            this.component.video_prev = "";
          }
          if (this.component.videourl.length > 0) {
            this.comtent_objets.push({
              title: `Video;(${this.duplicate("Video") + 1})`,
              tipo: "Video",
              contenido: {
                video: this.component.videourl,
                url: this.component.videourl,
              },
            });
            this.component.videourl = "";
          }
          this.name_comp = "";
          break;
        case "Documento":
          if (this.component.pdf) {
            this.comtent_objets.push({
              title: `Documento;(${this.duplicate("Documento") + 1})`,
              tipo: "Documento",
              contenido: {
                pdf: this.component.pdf,
                url: this.component.pdf_prev,
                name: this.component.pdf_name,
              },
            });
            this.component.pdf = "";
            this.component.pdf_prev = "";
            this.component.pdf_name = "";
          }
          this.name_comp = "";
          break;
        default:
          //console.log("no existe el tipo de componente");
          break;
      }
    },
    pushEdited() {
      var index = this.comtent_objets.findIndex(
        (ob) => ob.title == this.edit_selected_texto.title
      );
      this.edit_texto = false;
      switch (this.show_component) {
        case "Titulo":
          if (this.component.titulo) {
            this.comtent_objets.splice(index, 1, {
              title: this.edit_selected_texto.title,
              tipo: "Titulo",
              contenido: this.component.titulo,
              id: this.edit_selected_texto.id,
            });
            this.component.titulo = "";
          }
          this.name_comp = "";
          break;
        case "Texto":
          //console.log("content", this.news_content);
          if (this.news_content != "") {
            this.comtent_objets.splice(index, 1, {
              title: this.edit_selected_texto.title,
              tipo: "Texto",
              contenido: this.news_content,
              id: this.edit_selected_texto.id,
            });
            this.cleanComponent();
          }
          this.name_comp = "";
          break;
        case "Imagen":
          if (this.component.img) {
            this.comtent_objets.splice(index, 1, {
              title: this.edit_selected_texto.title,
              tipo: "Imagen",
              contenido: {
                foto: this.component.foto,
                img: this.component.img,
                url: this.component.url,
              },
              id: this.edit_selected_texto.id,
            });
            this.component.foto = "";
            this.component.img = "";
            this.component.url = "";
            this.hipervinculo_switch = false;
          }
          this.name_comp = "";
          break;
        case "Video":
          this.comtent_objets.splice(index, 1, {
            title: this.edit_selected_texto.title,
            tipo: "Video",
            contenido: {
              video: this.component.video,
              url: this.component.video_prev,
            },
            id: this.edit_selected_texto.id,
          });
          this.component.video = "";
          this.component.video_prev = "";
          this.name_comp = "";
          break;
        case "Documento":
          if (this.component.pdf) {
            this.comtent_objets.splice(index, 1, {
              title: this.edit_selected_texto.title,
              tipo: "Documento",
              contenido: {
                pdf: this.component.pdf,
                url: this.component.pdf_prev,
                name: this.component.pdf_name,
              },
              id: this.edit_selected_texto.id,
            });
            this.component.pdf = "";
            this.component.pdf_prev = "";
            this.component.pdf_name = "";
          }
          this.name_comp = "";
          break;
        default:
          break;
      }

      this.edit_selected_texto = {};
    },
    cleanComponent() {
/*       this.component = {
        img: "",
        foto: "",
        url: "",
        video: "",
        video_prev: "",
        pdf: "",
        pdf_prev: "",
        videourl: "",
        pdf_name: "",
      }; */
      //console.log("limpiando componente");
      this.user_name = "";
      this.user_list = [];
      this.user = {
        id_usuario: "",
        primer_nombre: "",
        apellido_paterno: "",
        link: "",
        medio: "",
      };
      this.news_content = "";
      setTimeout(() => {
        this.editor = new Editor({
          extensions: [
            Bold,
            Link,
            Document,
            Paragraph,
            Text,
            Italic,
            Underline,
            BulletList,
            OrderedList,
            ListItem,
            CharacterCount.configure({ limit: this.limit }),
          ],
          content: this.content,
          autofocus: false,
          onUpdate: ({ editor }) => {
            this.news_content = editor.getHTML();
          },
        });
        this.editor_key = 1 + this.editor_key;
      }, 200);
    },
    duplicate(dupli) {
      let filt = this.comtent_objets.filter((el) => el.tipo == dupli);
      //console.log(dupli,filt);
      return filt.length;
    },
    loadPreview(e) {
      //console.log(e);
      switch (e.tipo) {
        case "Texto":
          return e.contenido == null ? "" : e.contenido;
        case "Imagen":
          return e.contenido == null
            ? ""
            : `<a href="${e.contenido.url}" target="_blank" ><img src="${e.contenido.img}" alt="" /></a>`;
        case "Video":
          return e.contenido == null
            ? ""
            : `
            <video
              controls
              src="${e.contenido.url}"
              alt="Video"
              >
            </video>
                  `;
        case "Documento":
          return e.contenido == null
            ? ""
            : `<a target="_blank" href="${e.contenido.url}">
                              ${e.contenido.name}
                              <span>
                              <font-awesome-icon icon="link" ></font-awesome-icon>
                           </span></a>`;
        case "Reportero":
          if (e.contenido != null) {
            if (e.contenido.type == 1) {
              return `<font-awesome-icon
                                 icon="user"
                              ></font-awesome-icon> <p>${e.contenido.primer_nombre} ${e.contenido.apellido_paterno}</p>`;
            }
            if (e.contenido.type == 2) {
              return `<p>${e.contenido.primer_nombre} ${e.contenido.apellido_paterno} reportero de <a target="_blank" href="${e.contenido.link}">${e.contenido.medio}</a> </p>`;
            }
          } else {
            return "";
          }
      }
    },
    loadFiles(eve) {
      var file = eve.target;
      //console.log(file);
      var type = file.files[0].type.split("/")[0];
      var file_name = file.files[0].name;
      //console.log(type);

      var rea = new FileReader();
      if (file.files) {
        switch (type) {
          case "image":
            if (file.files[0].size < 20000000) {
              rea.onload = (e) => {
                //console.log(e.target.result);
                this.component.foto =
                  e.target.result.split(",")[
                    e.target.result.split(",").length - 1
                  ];
                this.component.img = e.target.result;
              };
              rea.readAsDataURL(file.files[0]);
            } else {
              alert("La imagen excede el tamaño maximo de 5 megas");
            }
            break;
          case "video":
            if (file.files[0].size < 100000000) {
              rea.onload = (e) => {
                //console.log(e.target.result);
                this.component.video =
                  e.target.result.split(",")[
                    e.target.result.split(",").length - 1
                  ];
                this.component.video_prev = e.target.result;
              };
              rea.readAsDataURL(file.files[0]);
            } else {
              alert("el video excede el tamaño maximo de 100 megas");
            }
            break;
          case "application":
            if (file.files[0].size < 5000000) {
              rea.onload = (e) => {
                //console.log(e.target.result);
                this.component.pdf =
                  e.target.result.split(",")[
                    e.target.result.split(",").length - 1
                  ];
                this.component.pdf_prev = e.target.result;
                this.component.docname = file_name;
                this.component.size = file.files[0].size / 1000000;
              };
              rea.readAsDataURL(file.files[0]);
            } else {
              this.open_modal_status = true;
              this.file_limit_mb = true;
              this.modal_status = false;
              this.modal_status_msg = "El documento excede los 5MB";
              this.bad_document = 1;
            }
            break;
        }
      }
    },
    acceptStatus() {
      this.open_modal_status = false;
      this.modal_status_msg = "";
      if (this.bad_document == 0) {
        this.bad_document = 0;
        this.$router.push({
          name: "culture-list",
        });
      }
    },
    // funcion para abrir y cerrar select de categorias
    toggleSelect() {
      if (this.open_select) {
        this.open_select = false;
      } else {
        this.open_select = true;
      }
    },
    //al dropear
    onDrop() {
      //console.log(this.comtent_objets);
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    deleteComponent(co, i) {
      console.table("Delete", co, this.comtent_objets[i]);

      if (co.id == undefined) {
        this.comtent_objets.splice(i, 1); //nuevos
      } else {
        this.comtent_objets[i].contenido = null; //desde el ws
      }
    },
    editText(comp, index) {
      //console.log("comp", comp, index);
      switch (comp.tipo) {
        case "Titulo":
          //console.log("titulo", this.comtent_objets[index]);
          this.edit_selected_texto = this.comtent_objets[index];
          this.edit_selected_texto.index = index;
          this.show_component = "Titulo";

          this.component.titulo = comp.contenido;
          this.edit_texto = true;
          break;
        case "Texto":
          this.edit_selected_texto = this.comtent_objets[index];
          this.edit_selected_texto.index = index;
          this.show_component = "Texto";
          this.editor = new Editor({
            extensions: [
              Bold,
              Link,
              Document,
              Paragraph,
              Text,
              Italic,
              Underline,
              BulletList,
              OrderedList,
              ListItem,
              CharacterCount.configure({
                limit: this.limit,
              }),
            ],
            content: comp.contenido,
            autofocus: false,
            onUpdate: ({ editor }) => {
              this.news_content = editor.getHTML();
            },
          });
          this.news_content = comp.contenido;
          this.editor_key = 1 + this.editor_key;
          this.edit_texto = true;
          break;
        case "Imagen":
          this.edit_selected_texto = this.comtent_objets[index];
          this.edit_selected_texto.index = index;
          this.show_component = "Imagen";
          this.hipervinculo_switch = comp.contenido.url != "";
          this.component.foto = comp.contenido.foto;
          this.component.img = comp.contenido.img;
          this.component.url = comp.contenido.url;
          this.edit_texto = true;
          break;
        case "Video":
          this.edit_selected_texto = this.comtent_objets[index];
          this.edit_selected_texto.index = index;
          this.show_component = "Video";
          this.component.video = comp.contenido.url;
          this.component.video_prev = comp.contenido.url;
          this.component.videourl = comp.contenido.url;
          this.component.url = comp.contenido.url;
          this.edit_texto = true;
          break;
        case "Documento":
          this.edit_selected_texto = this.comtent_objets[index];
          this.edit_selected_texto.index = index;
          this.show_component = "Documento";

          this.component.pdf = comp.contenido.url;
          this.component.pdf_prev = comp.contenido.url;
          this.component.pdf_name = comp.contenido.name;
          this.component.docname = comp.contenido.name;
          this.component.size = 0;

          this.edit_texto = true;
          break;
        default:
          break;
      }
    },
    //Formatear fecha
    dateNewFormat(date, type) {
      var date_day = new Date(date);
      var date_f = new Date(date);
      date_f.setDate(date_f.getDate() + 1);
      var month = date_f.getMonth();

      if (type == 1) {
        return `${date_f.getDate()};${this.meses[month]}`;
      }
      if (type == 2) {
        // console.log(
        //   "fecha",
        //   `${date_day.getDate()}/${
        //     month + 1 < 10 ? `0${month + 1}` : month + 1
        //   }/${date_day.getFullYear()}`
        // );
        return `${date_day.getDate()}/${
          month + 1 < 10 ? `0${month + 1}` : month + 1
        }/${date_day.getFullYear()}`;
      }
    },
  },
};
</script>
